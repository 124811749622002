import React from "react";

export default function Navigation() {
  return (
    <header class="masthead">
      <div class="container px-5">
        <div class="row gx-5 align-items-center">
          <div class="col-lg-6">
            <div class="mb-5 mb-lg-0 text-center text-lg-start">
              <h1 class="display-1 lh-1 mb-3">
                Be the admin of your native place
              </h1>
              <p class="lead fw-normal text-muted mb-5">
                Onnich makes it easy to launch a mobile application for your
                native place.
              </p>
              <div class="d-flex flex-column flex-lg-row align-items-center">
                <a class="me-lg-3 mb-4 mb-lg-0" href="#!">
                  <a
                    style={{
                      padding: "1rem",
                      boxShadow: "rgb(0 0 0 / 5%) 0px 0.25rem 0.75rem",
                      borderRadius: ".7rem",
                      fontSize: ".8em",
                      display: "flex",
                    }}
                    href="https://api.whatsapp.com/send?phone=919946792650&text=Hi,%20I%20am%20contacting%20you%via%20Onnich.%0A%0A"
                  >
                    <i
                      class="bi bi-whatsapp"
                      style={{
                        marginRight: ".5rem",
                        fontSize: "1rem",
                      }}
                    ></i>
                    <div
                      style={{
                        paddingTop: ".1rem",
                      }}
                    >
                      Message us on Whatsapp
                    </div>
                  </a>
                </a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="masthead-device-mockup">
              <img
                class=""
                src="/assets/img/onnich-landing.png"
                alt="..."
                style={{
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
