import React, { useEffect } from "react";

export default function PrivacyPolicy() {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.onnich.parambath";
    }
  }, []);

  return null;
}
