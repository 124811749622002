import React from "react";

export default function Navigation() {
  return (
    <footer class="bg-black text-center py-5">
      <div class="container px-5">
        <div class="social-accounts-section">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a
                href={`https://api.whatsapp.com/send?phone=919946792650&text=Hi, I am contacting you via Onnich.%0A%0A`}
              >
                <i class="bi bi-whatsapp"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a
                href="https://facebook.com/onnichcom"
                target="_blank"
                class="btn-sm btn-default btn-lg"
              >
                <i class="bi bi-facebook"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a
                href="https://instagram.com/onnich.com"
                target="_blank"
                class="btn-sm btn-default btn-lg"
              >
                <i class="bi bi-instagram"></i>
              </a>
            </li>

            <li class="list-inline-item">
              <a
                // href="https://www.youtube.com/parambathapp"
                target="_blank"
                class="btn-sm btn-default btn-lg"
              >
                <i class="bi bi-youtube"></i>
              </a>
            </li>
          </ul>
        </div>

        <div class="text-white-50 small">
          <p
            style={{
              fontSize: ".9em",
              textAlign: "center",
            }}
          >
            Made with Love in God's own country
            <br />
            Copyright ©&nbsp;
            <a href="https://floyet.com" target="_blank">
              Floyet Technologies LLP
            </a>
            &nbsp;2021 | All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
