import React, { useState } from "react";

export default function Navigation() {
  const [copied, setCopied] = useState(false);

  function copyToClipboard() {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    setCopied(true);
  }

  return (
    <div>
      <aside class="text-center bg-gradient-primary-to-secondary">
        <div class="container px-5">
          <div class="row gx-5 justify-content-center">
            <div class="col-xl-8">
              <div class="h2 fs-1 text-white mb-4">
                "Parambath App powered by ONNICH helps the people in Parambath
                to access the information and notifications instantly."
              </div>
              <a href="https://parambath.onnich.com" target="_blank">
                <img
                  src="assets/img/parambath.png"
                  alt="..."
                  style={{ height: "4rem", borderRadius: "1rem" }}
                />
              </a>
            </div>
          </div>
        </div>
      </aside>

      <section id="features">
        <div class="container px-5">
          <div class="row gx-5 align-items-center">
            <h2 class="text-center font-alt mb-4">Features</h2>
            <p class="lead text-center">
              Onnich provides a number of features that help admins to manage
              all information and notifications relevant to their native place.
            </p>
            <div class="col-lg-8 order-lg-1 mb-5 mb-lg-0">
              <div class="container-fluid px-5">
                <div class="row gx-5">
                  <div class="col-md-6 mb-5">
                    <div class="text-center">
                      <i class="bi-speedometer2 icon-feature text-gradient d-block mb-3"></i>
                      <h3 class="font-alt">Interactive admin dashboard</h3>
                      <p class="text-muted mb-0">
                        You will get an admin dashboard to manage the
                        information to be shown on the app.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 mb-5">
                    <div class="text-center">
                      <i class="bi-phone icon-feature text-gradient d-block mb-3"></i>
                      <h3 class="font-alt">Beautiful mobile app</h3>
                      <p class="text-muted mb-0">
                        A mobile app made using the latest technology that is
                        powerful to show all the information from the admin
                        dashboard.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-5 mb-md-0">
                    <div class="text-center">
                      <i class="bi-laptop icon-feature text-gradient d-block mb-3"></i>
                      <h3 class="font-alt">Website for the app</h3>
                      <p class="text-muted mb-0">
                        A website will also be provided with this package to
                        show relevant information and download link.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="text-center">
                      <i class="bi-cash-coin icon-feature text-gradient d-block mb-3"></i>
                      <h3 class="font-alt">Free</h3>
                      <p class="text-muted mb-0">
                        Do not worry about monthly payments and development
                        costs. We are providing this package free for all.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 order-lg-0">
              <div class="features-device-mockup">
                <img
                  src="/assets/img/features.png"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-light">
        <div class="container px-5">
          <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
            <div class="col-12 col-lg-5">
              <h2 class="display-4 lh-1 mb-4">A website for your App</h2>
              <p class="lead fw-normal text-muted mb-5 mb-lg-0">
                Onnich also provides a website for your application where you
                can list the features, Play Store, and App Store link, etc.
              </p>
            </div>
            <div class="col-sm-8 col-md-6">
              <div class="px-5 px-sm-0">
                <img
                  class="img-fluid"
                  style={{
                    borderRadius: ".8rem",
                    boxShadow: "rgb(0 0 0 / 5%) 0px 0.25rem 0.75rem",
                  }}
                  src="/assets/img/parambath-website.png"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="cta" id="demo">
        <div class="cta-content">
          <div class="container px-5">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <h4 class="text-white display-1 lh-1 mb-4">
                  Feel the app powered by Onnich using Expo
                </h4>
                <ul
                  style={{
                    listStyle: "none",
                    color: "white",
                  }}
                >
                  <li
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    1. Download the&nbsp;
                    <a href="https://play.google.com/store/apps/details?id=host.exp.exponent">
                      Expo app
                    </a>
                  </li>
                  <li
                    style={{
                      marginBottom: "1rem",
                    }}
                  >
                    2. Scan the QR code
                  </li>
                  <li>
                    3. Alternatively you can copy the below URL, open expo app
                    and click <b>paste from the clipboard</b> option
                  </li>
                </ul>
                <input
                  type="text"
                  value="exp://exp.host/@syamjayaraj/Parambath"
                  id="myInput"
                  style={{
                    display: "none",
                  }}
                />
                <p
                  style={{
                    padding: ".4rem",
                    background: "#f1f1f1",
                    borderRadius: ".2rem",
                    width: "fit-content",
                    cursor: "pointer",
                    overflowWrap: "anywhere",
                  }}
                  onClick={() => copyToClipboard()}
                >
                  exp://exp.host/@syamjayaraj/Parambath
                  {copied ? (
                    <span
                      style={{
                        color: "green",
                        marginLeft: "1rem",
                      }}
                    >
                      Copied
                    </span>
                  ) : null}
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <img
                  src="/assets/img/expo.png"
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
