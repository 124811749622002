import React, { useState } from "react";

export default function Navigation() {
  const [showMenu, setShowMenu] = useState(false);

  let toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <nav
      class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
      id="mainNav"
    >
      <div class="container px-5">
        <a class="navbar-brand fw-bold" href="/">
          ONNICH
        </a>
        <button class="navbar-toggler" onClick={toggleMenu}>
          Menu
          <i class="bi-list"></i>
        </button>
        <div
          class="collapse navbar-collapse"
          id="navbarResponsive"
          style={{ display: showMenu ? "block" : "none" }}
        >
          <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
            <li class="nav-item">
              <a
                class="nav-link me-lg-3"
                href="#features"
                onClick={() => setShowMenu(false)}
              >
                Features
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link me-lg-3"
                href="#demo"
                onClick={() => setShowMenu(false)}
              >
                Demo
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link me-lg-3"
                href="#pricing"
                onClick={() => setShowMenu(false)}
              >
                Pricing
              </a>
            </li>
          </ul>
          <button
            class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
            onClick={() => setShowMenu(false)}
          >
            <a
              href="tel:+919946792650"
              style={{
                color: "white",
                textDecoration: "none",
              }}
            >
              <span class="d-flex align-items-center">
                <i class="bi-telephone-fill me-2"></i>
                <span class="small">+919946792650</span>
              </span>
            </a>
          </button>
        </div>
      </div>
    </nav>
  );
}
