import React from "react";

export default function Pricing() {
  return (
    <section class="bg-gradient-primary-to-secondary" id="pricing">
      <div class="container px-2">
        <div>

          <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
            <h2 class="text-center text-white font-alt mb-4">Pricing</h2>

            <p class="lead text-white">
              Onnich provides the admin dashboard and mobile application for
              free. But a one-time payment should be paid for setting
              up the server and Google Play Store account.
            </p>
            <p class="lead text-white">
             Contact the team for details
            </p>
          </div>

          {/* <div class="container">
            <div class="row card-deck mb-3 text-center">
              <div
                class="card col-lg-3 col-md-6 col-12 mb-3"
                style={{
                  boxShadow: "0 0.25rem 0.75rem rgb(0 0 0 / 5%)",
                }}
              >
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal">Free</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    ₹0.00 <small class="text-muted">/ month</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li>Admin dashboard + mobile app</li>
                    <li>1 admin included</li>
                    <li>Unlimited storage</li>
                    <li>Telephone support</li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-outline-primary"
                  >
                    Contact us
                  </button>
                </div>
              </div>

              <div
                class="card col-lg-3 col-md-6 col-12 mb-3"
                style={{
                  boxShadow: "0 0.25rem 0.75rem rgb(0 0 0 / 5%)",
                }}
              >
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal">Pro</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    ₹1999 <small class="text-muted">/ month</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li>Upto 3 admin account</li>
                    <li>Priority telephone support</li>
                    <li>4 business Emails</li>
                    <li>Website for the app</li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-primary"
                  >
                    Contact us
                  </button>
                </div>
              </div>

              <div
                class="card col-lg-3 col-md-6 col-12 mb-3"
                style={{
                  boxShadow: "0 0.25rem 0.75rem rgb(0 0 0 / 5%)",
                }}
              >
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal">Enterprise</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">
                    ₹2999 <small class="text-muted">/ month</small>
                  </h1>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li>Customize the app theme and functions</li>
                    <li>Unlimited admins</li>
                    <li>Business Email for all admins</li>
                    <li>Customized website for the app</li>
                    <li>Custom domain for the website</li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-primary"
                  >
                    Contact us
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
